import styled from "styled-components";
import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import Andrew from "../../images/team/advisory/Andrew.jpg";
import Claudine from "../../images/team/advisory/Claudine.jpg";
import Deborah from "../../images/team/advisory/Deborah.jpg";
import DintiBatsone from "../../images/team/advisory/Dinti-Batsone.jpg";
import moore from "../../images/team/advisory/Emma-Moore.jpg";
import emma from "../../images/team/advisory/emma-turnball.jpg";
import Hollie from "../../images/team/advisory/Hollie-Crompton.jpg";
import Julia from "../../images/team/advisory/Julia-Constantine.jpg";
import Kate from "../../images/team/advisory/Kate-Eggers.jpg";
import Leo from "../../images/team/advisory/Leo-Pic.jpg";
import Louise from "../../images/team/advisory/Louise-Ashley.jpg";
import Nicholas from "../../images/team/advisory/Nicholas-Cornwell.jpg";
import Phil from "../../images/team/advisory/Phil-Wilson.jpg";
import Pras from "../../images/team/advisory/Pras.jpg";
import Rachel from "../../images/team/advisory/Rachel-Glaze.jpg";
import Reggie from "../../images/team/advisory/Reggie-Headshot.jpg";
import Sarah from "../../images/team/advisory/Sarah-Webster.jpg";
import Tania from "../../images/team/advisory/Tania-Lyden.jpg";

import kelena from "../../images/team/patron/Kelena_kennedy.jpg";

import Aishah from "../../images/team/staff/Aishah.png";
import Alex from "../../images/team/staff/Alex.png";
import Andy from "../../images/team/staff/Andy.png";
import Anna from "../../images/team/staff/Anna.png";
import Becca from "../../images/team/staff/Becca.png";
import Dan from "../../images/team/staff/Dan.png";
import David from "../../images/team/staff/David.png";
import Dulcie from "../../images/team/staff/Dulcie.png";
import Ella from "../../images/team/staff/Ella.png";
import freya from "../../images/team/staff/freya.png";
import Gravin from "../../images/team/staff/Gavin.png";
import Halima from "../../images/team/staff/Halima.png";
import stoddart from "../../images/team/staff/hannah-stoddart.png";
import Hannah from "../../images/team/staff/Hannah.png";
import Iria from "../../images/team/staff/Iria.png";
import Jacque from "../../images/team/staff/Jacque.png";
import Jamie from "../../images/team/staff/Jamie.png";
import john from "../../images/team/staff/john-craven.png";
import Laura from "../../images/team/staff/Laura.png";
import Lee from "../../images/team/staff/Lee.png";
import Liam from "../../images/team/staff/Liam.png";
import Liberty from "../../images/team/staff/Liberty.png";
import Mabel from "../../images/team/staff/Mabel.png";
import Muhammad from "../../images/team/staff/Muhammad-Gangat.png";
import Niamh from "../../images/team/staff/Niamh.png";
import Sarah1 from "../../images/team/staff/Sarah.png";
import Olivia from "../../images/team/staff/Olivia-Reeve.png";
import Rebecca from "../../images/team/staff/Rebecca.png";
import Sam from "../../images/team/staff/Sam.png";
import Sara from "../../images/team/staff/Sara.png";
import sophie from "../../images/team/staff/sophie.png";
import Stephanie from "../../images/team/staff/Stephanie-Lieber.png";
import Tabby from "../../images/team/staff/Tabby.png";
import Yolanda from "../../images/team/staff/Yolanda.png";
import lidia from "../../images/team/staff/lidia.png";
import sam_fra from "../../images/team/staff/Sam_Fraine.png";

import Marshall from "../../images/team/trustees/Al-Marshall.jpg";
import Alexander from "../../images/team/trustees/Alexander.jpg";
import Amy from "../../images/team/trustees/Amy.jpg";
import Azhar from "../../images/team/trustees/Azhar-Chaudhry.jpg";
import Edward from "../../images/team/trustees/Edward-Astle.jpg";
import Graeme from "../../images/team/trustees/Graeme-Atherton.jpg";
import Katy from "../../images/team/trustees/Katy-Ingle.jpg";
import Owain from "../../images/team/trustees/Owain-Service.jpg";
import TIF from "../../images/team/trustees/TIF-Work-photo.jpg";
import Valentina from "../../images/team/trustees/Valentina-Quintero-Rodriguezjpg.jpg";
import Jenna2 from "../../images/team/advisory/Jenna.png";

const StyledWrapper = styled.div`
  margin-top: 80px;
  .team-categories {
    font-size: ${props => props.theme["FooterLinkSize"]};
    font-weight: bold;
    letter-spacing: ${props => props.theme["zero"]};
    line-height: ${props => props.theme["footerLinkLineHeight"]};
    text-transform: uppercase;
    @media (max-width: 600px) {
      font-size: ${props => props.theme["externalLinkSize"]} !important;
    }
  }
  .active,
  .team-categories:hover .border-on-hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 3px solid ${props => props.theme["borderBottomColor"]};
  }
  .container {
    position: relative;
    width: ${props => props.theme["percent-100"]};
  }
  .image {
    display: block;
    width: ${props => props.theme["percent-100"]};
    height: auto;
  }
  .overlay {
    position: absolute;
    top: ${props => props.theme["zero"]};
    bottom: ${props => props.theme["zero"]};
    left: 0px;
    right: ${props => props.theme["zero"]};
    height: ${props => props.theme["percent-100"]};
    width: 100%;
    opacity: ${props => props.theme["zero"]};
    transition: 0.2s ease;
    background-color: ${props => props.theme["externalLinkColor"]};
  }
  .container:hover .overlay {
    opacity: 1;
  }
  .text {
    color: ${props => props.theme["secondaryColor"]};
    font-size: ${props => props.theme["externalLinkSize"]};
    position: absolute;
    top: ${props => props.theme["percent-50"]};
    left: ${props => props.theme["percent-50"]};
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 95%;
  }
  .user-profile {
    width: 100%;
    height: 100%;
  }
  .john-email {
    color: black;
  }
  .advisory-board {
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  .upreach-employee-img {
    object-position: top;
    object-fit: cover;
    width: 100%;
    min-height: 325px;
  }

  .team-names,
  .team-title {
    font-weight: bold;
    font-size: 18px;
  }

  .team-description {
    font-size: 14px;
  }

  .padding-sides {
    padding-left: 0px;
    padding-right: 0px;
  }
  .team-nav {
    padding-right: 35px;
  }
  @media (max-width: 1091px) {
    .team-nav-row {
      display: flex;
      flex-direction: column;
    }
  }
  .team-row{
    margin-left: -15px;
  }
`;

const TeamInfo = [
  {
    teamCategory: [
      { title: "Staff" },
      { title: "Trustees" },
      { title: "Patron" },
      { title: "Development Board"},
      { title: "Advisory Group" },
      { title: "Alumni Ambassador Board"}
    ]
  },
  {
    images: [
      {
        patronTeamImage: [
          {
            image: `${kelena}`,
            name: "Helena Kennedy QC",
            title: "Lawyer",
            description:
              "Helena Kennedy QC is one of the United Kingdom's most distinguished lawyers. She has practised at the Bar for 40 years in the field of criminal law and has conducted many of the leading cases including Balcombe Street Siege, Brighton bombing trial, Guildford Four Appeal, Michael Bettany Espionage case, Jihadist Fertiliser Bomb Plot and Transatlantic Bomb Plot. ",
          },
        ],
        advisorTeamImage: [
          {
            image: `${Andrew}`,
            name: "Andrew Bargery",
            title: "Talent Engagement Senior Manager",
            description:
              "Andrew joined PwC in 2003 and in his current role he leads on the work that the Student Recruitment team does with Universities and Schools in order to attract the most talented and diverse students across the firm’s school and college, undergraduate and graduate programmes. He also leads on the relationships that PwC has with partners such as upReach and Teach First.",
          },
          {
            image: `${Claudine}`,
            name: "Claudine Adeyemi",
            title: "Associate, Founder & CEO",
            description:
              "Associate at Mishcon de Reya LLP, Founder & CEO of The Student Development Co., Founder & CEO of Career Ear.",
          },
          // {
          //   image: `${Deborah}`,
          //   name: "Deborah Joseph",
          //   title: "Diversity Recruiter",
          //   description:
          //     "Deborah was part of the founding team at upReach, where she worked for almost four years in a number of different roles including leading the first Associate programmes. She is currently a diversity recruiter at McKinsey & Company.",
          // },
          {
            image: `${DintiBatsone}`,
            name: "Dinti Batstone",
            title: "Lawyer",
            description:
              "Dinti began her career as an international lawyer at Linklaters and FremantleMedia, gaining an MBA at INSEAD. Politically active for over a decade as a policy-maker and campaigner, she is also a trustee of the Fawcett Society and board member of the Arnold Foundation.",
          },
          {
            image: `${Hollie}`,
            name: "Hollie Crompton",
            title: "Social Mobility Lead",
            description:
              "Hollie joined PwC on the audit school leaver programme in 2004 and qualified as a chartered accountant in 2008. She has worked in a number of roles related to the firm’s social mobility strategy including learning and development, student recruitment and schools engagement. As part of PwC’s dedicated social mobility team formed in 2016, Hollie has led on key projects including the expansion of PwC’s formal work experience programme, the new social mobility community programme and launched a data project focused on gathering socio-economic background data on all of PwC’s 20,000 people to help inform and measure the social mobility strategy.",
          },
          {
            image: `${moore}`,
            name: "Emma Moore",
            title: "Head of Careers & Employability",
            description:
              "Emma Moore is the Head of Careers & Employability at the University of Liverpool. She chairs the AGCAS Employer Engagement Task Group sharing best practice around university and employer engagement nationally. Emma is also a member of the AGR Policy Group.",
          },
          {
            image: `${emma}`,
            name: "Emma Turnbull",
            title: "Pro Bono & Community Investment Officer",
            description:
              "Emma is a CSR professional with over 20 years experience, managing Allen & Overy’s educational programmes with a particular focus on social mobility. Emma has a been a Trustee of the London Legal Support Trust since 2011 and in her previous role managed the firm’s pro bono legal advice clinic work.",
          },
          {
            image: `${Jenna2}`,
            name: "Jenna Follett",
            title: "Talent Acquistion Lead",
            description:
              "Jenna currently leads a talent acquisition team at Bloomberg. During her career she's held a variety of HR roles including business partnering and managing recruitment teams at the early career, graduate and senior hire levels. Jenna is passionate about diversity and has held leadership roles within the Women’s and Working Families Communities at Bloomberg. Jenna has also been an advocate for social mobility through Bloomberg's Apprenticeship program. Jenna has international experience from both working in and managing teams across multiple locations including the UK, Australia, Hong Kong, Singapore, Tokyo and New York.",
          },
          {
            image: `${Sarah}`,
            name: "Sarah Webster",
            title: "Director, Greenhouse Sports",
            description:
              "Sarah led City Gateway’s corporate partnerships team and oversaw the creation of over 1000 jobs for disadvantaged young people.  Today she is responsible for Development and Communications at Greenhouse Sports.  She is also Chair of youth charity TwentyTwenty.",
          },
          {
            image: `${Kate}`,
            name: "Kate Foster",
            title: "Widening Participation Officer",
            description:
              "Kate has over 20 years’ experience working as a careers Consultant/Coach and is passionate about supporting individuals to fulfil their career potential. Based for the last 10 years in the Higher Education sector with a particular focus on widening participation, she is now working at the University of Exeter.",
          },
          // {
          //   image: `${Julia}`,
          //   name: "Julia Constantine",
          //   title: "HR Business Partner",
          //   description:
          //     "Julia currently works at Mercer as an HR Business partner within the HR Regional Leadership team focusing on Europe. Previously she managed the sourcing, selection and screening of candidates for junior positions in a dynamic management consultancy. Strongly committed to diversity and inclusion, she was involved in setting up D&I initiatives for the London office and led the partnership with upReach.",
          // },
          {
            image: `${Leo}`,
            name: "Michael LeoValan",
            title: "Co-Founder and CTO",
            description:
              "Michael LeoValan is the co-founder and CTO of JMAN Group. Leo is passionate about technology, with over 25 years of deep technical background and multiple leadership roles in Silicon Valley, CA and in India. He advises product startups and large corporations on digital transformation and data strategies. His belief in equal opportunity for different social classes has brought him closer to upReach.",
          },
          {
            image: `${Louise}`,
            name: "Dr. Louise Ashley",
            title: "Lecturer",
            description:
              "Dr. Louise Ashley specialises in researching diversity and inclusion across leading professions. In 2015 and 2016, she led the teams appointed by the government’s Social Mobility Commission to identify and understand barriers to the elite professions on the basis of socio-economic background.",
          },
          {
            image: `${Nicholas}`,
            name: "Nicholas Cornwell",
            title: "Manager - Clara Analytics Team",
            description:
              "Nick works within the Clara Analytics Team at KPMG. He was previously in Graduate Recruitment and Outreach at KPMG. In 2015, Nick created and delivered Professional Experience Programmes for students across the UK as well as attending policy discussions and debates around social mobility, barriers to work and higher education.",
          },
          {
            image: `${Phil}`,
            name: "Phil Wilson",
            title: "Chief Psychologist and Assessor",
            description:
              "Phil is the Chief Psychologist and Chief Assessor of the Civil Service Fast Stream, focusing on assessment design and diversity initiatives. Prior to this, he was Head of Occupational Psychology at the London Fire Brigade and Acting Head of Occupational Psychology for Greater Manchester Police.",
          },
          {
            image: `${Pras}`,
            name: "Prasanna Kannan",
            title: "Co-Founder",
            description:
              "Pras is a cofounder at Native Finance, an award-winning fintech in real estate. He was previously the Portfolio Manager at the Elton John AIDS Foundation and started his career at Goldman Sachs. He has been a mentor through various social mobility charities for over 15 years.",
          },
          {
            image: `${Rachel}`,
            name: "Rachel Glaze",
            title: "Resourcing Lead",
            description:
              "Rachel is the Resourcing Lead for Aviva in the UK. She has worked at Aviva for 17 years in a variety of HR roles across talent, recruitment and business partnering. Prior to joining Aviva Rachel worked for over 10 years at British Airways Holidays in Customer Service management roles. Rachel is passionate about helping to unlock opportunities for people, at all career stages, to enable them to do the best work of their lives.",
          },
          {
            image: `${Reggie}`,
            name: "Reggie Nelson",
            title: "Analyst, LGIM",
            description:
              "Reggie Nelson is a Graduate Analyst at Legal and General Investment Management; is currently the Group Chair for ACCA Emerging Talent Advisory Group, and has previously worked with the Cabinet Office and Downing St to help tackle ethnic and social disparities",
          },
          {
            image: `${Tania}`,
            name: "Tania Lyden",
            title: "Career Mentoring Manager and Doctoral Researcher",
            description:
              "Tania is Career Mentoring Manager and Doctoral Researcher at the University of Reading. Her research focuses on the impact of social diversity on the success of career mentoring partnerships in Higher Education. Tania also has experience of graduate recruitment for PricewaterhouseCoopers.",
          },
        ],
        trusteeTeamImage: [
          {
            image: `${Marshall}`,
            name: "Al Marshall",
            title: "Trustee",
            description:
              "Al is an experienced Financial and Operational Turnaround Executive, currently working in McKinsey’s Restructuring Team. Before joining McKinsey, Al qualified as a Chartered Accountant with PwC in their Restructuring Team where he spent 14 years working on assignments based out of London, New York, Zurich and Paris.",
          },
          {
            image: `${Alexander}`,
            name: "Alexander Peake",
            title: "Trustee",
            description:
              "Alex is currently working as an associate at AllianceBernstein in Fixed Income. Prior to this he completed placements in Corporate Finance and Private Banking. He holds an MSc in Economic History from The London School of Economics.",
          },
          {
            image: `${Amy}`,
            name: "Amy Dicketts",
            title: "Trustee",
            description:
              "Amy is a Product Manager at Monzo Bank, where she works on the delivery of new features within the Borrowing team. Prior to this she worked at the British Heart Foundation, where she helped lead a programme of digital transformation within their Fundraising department to improve online user experiences and build out their digital team’s capabilities.",
          },
          {
            image: `${Azhar}`,
            name: "Azhar Chaudhry",
            title: "Trustee",
            description:
              "Azhar studied Politics, Philosophy and Economics at the University of Exeter. Having recently graduated, he is working as a Management consultant and served as a Trustee at the University of Exeter Student’s Guild.",
          },
          {
            image: `${Edward}`,
            name: "Edward Astle",
            title: "Chair of Trustees",
            description:
              "Edward is currently Chair of the Board of Governors at the University of Manchester and a non-executive director of Openreach Ltd. Much of his earlier career was in the telecoms and utilities sectors, where he was successively a main board director of Cable and Wireless, BICC and National Grid. From 2008 to 2013 he was Pro-Rector Enterprise at Imperial College London. As a non-executive, he has served on the board of Intertek Plc and on BT’s Equality of Access Board, and has been deputy chair of Engie UK’s Advisory Board and of the prisoner reading charity Shannon Trust.",
          },
          {
            image: `${Graeme}`,
            name: "Graeme Atherton",
            title: "Trustee",
            description:
              "Graeme is an international leader & researcher in the field of access and diversity in higher education. Graeme founded and leads both AccessHE and the National Education Opportunities Network (NEON). He holds Visiting Professorships at London Metropolitan University, Amity Business School and Sunway University, Kuala Lumpur. He also wrote ‘Charting Equity: Drawing the Global Access Map’ and ‘Access to Higher Education: Understanding Global Inequalities’.",
          },
          {
            image: `${Katy}`,
            name: "Katy Ingle",
            title: "Trustee",
            description:
              "Katy is the Head of Diversity and Inclusion at Bank of America, covering Europe, Middle East and Africa (EMEA) region within the EMEA Talent function. She leads a team in creating and leading on the organisations diversity and inclusion strategy, including partnering with HR partners to embed diversity and inclusion practice into our business and people strategies, oversight of our Employee Networks and partnering with external organisations in order to maximize our diversity and inclusion efforts.",
          },
          {
            image: `${Owain}`,
            name: "Owain Service",
            title: "Trustee",
            description:
              "Owain is the former Managing Director and Co-Founder of the Behavioural Insights Team. Currently, Owain is enjoying a few months of parental leave.",
          },
          {
            image: `${TIF}`,
            name: "Timothy Fosh",
            title: "Trustee",
            description:
              "Tim is a senior associate in the Financial Regulation team at Slaughter and May, where he has been since he started his legal career a decade ago. He has a particular interest in FinTech and the use cases for AI in financial services and is a regular blogger on the topics. Outside of work, Tim has considerable experience volunteering for various social mobility enterprises, principally as a mentor.",
          },
          {
            image: `${Valentina}`,
            name: "Valentina Quintero-Rodriguez",
            title: "Trustee",
            description:
              "An upReach Alumnus, Valentina now works as an Executive Officer for Projects and Planning with London College of Fashion. Prior to this, she completed a BA in English Language and Communication at Kings College London where she also worked as a Research Assistant. Her first published article, ‘UK higher education institutions’ technology-enhanced learning strategies from the perspective of disruptive innovation’, was published by the Research in Learning Technology and the Distance Education in China academic journals.",
          },
        ],
        teamImage: [
          {
            image: `${john}`,
            name: "John Craven",
            title: "Chief Executive",
            description:
              "John has been actively involved in social mobility since founding the successful Cambridge University Shadowing Scheme in 1999. Prior to joining upReach, he spent 11 years in investment banking, and as a Lead Practitioner, taught Maths and Economics in state and independent schools. A charity trustee for seven years, John likes skiing, marathons, coffee and red wine.",
          },
          {
            image: `${Stephanie}`,
            name: "Stephanie Lieber",
            title: "Director of Programmes and Impact",
            description:
              "Stephanie joined upReach after working in the Corporate world as a tax director for 25 years. She wanted to join upReach so she could share insights she gained while developing her own career with Associates as they are launching their careers. After joining upReach in the first FCLP cohort in 2016, she now leads the delivery team to ensure we meet our commitments to our Associates and Partners as the Director of Programmes and Impact.",
          },
          {
            image: `${Sara}`,
            name: "Sara Furness",
            title: "Chief of Staff",
            description:
              "Sara is the Chief of Staff, working on governance, team coordination and internal project management. She joined upReach in 2020 after having worked previously for a mix of charitable and educational organisations. She is passionate about ensuring all students have access to the opportunities they need in life to reach their full potential. Her other interests include travelling, reading and (usually) being disappointed by Crystal Palace Football Club.",
          },
          {
            image: `${Aishah}`,
            name: "Aishah Nahar",
            title: "Programme Coordinator",
            description:
              "Aishah is a recent History graduate with an interest in education, international development, and increasing social mobility for marginalized groups. During her studies she worked in widening participation to help students gain insight into university life, build their confidence, and help them realise their potential. As an upReach alumni she learned firsthand about the incredible opportunities for growth provided by upReach to eligible students. She now hopes to provide the same invaluable support to current Associates. Aishah can be found with her head in a good book, making art, or watching history documentaries.",
          },
          {
            image: `${Alex}`,
            name: "Alex Veitch",
            title: "Programme Leader",
            description:
              "Alex joined the upReach Manchester office after graduating from the University of Cambridge with a determination to help tackle social and educational inequality. Prior to receiving her English degree, Alex was extensively involved in her college and wider university access work. As the first person in her family to go to university and a former pupil of a state comprehensive school in Greater Manchester, she understands that social mobility issues do not end once graduation is over. She is passionate about helping Associates realise their brilliance and potential. She can be found with either a coffee, book or online shopping order in her hand",
          },
          {
            image: `${Andy}`,
            name: "Andy Harding",
            title: "Law Springboard Lead",
            description:
              "Andy joined upReach because he is passionate about ensuring that all students have the knowledge needed to access opportunities that will allow them to develop the skills to go on to access top graduate jobs. His role is to support a new cohort of aspiring law professionals in doing exactly that. In his spare time, he likes to read, spend time in the great outdoors and travel overseas.",
          },
          {
            image: `${Anna}`,
            name: "Anna Sawicki Smith",
            title: "Programme Leader",
            description:
              "During her time at university, Anna worked with school students from less privileged backgrounds to gain the skills needed to attend university. She joined upReach as a Programme Coordinator to help students with the next step of gaining access to top graduate jobs. In her free time she enjoys cooking, exploring London by bus and finding bargains in charity shops.",
          },
          {
            image: `${Dan}`,
            name: "Daniel Davis",
            title: "Programme Coordinator",
            description:
              "Dan is a recent Philosophy graduate from the University of Exeter. Having been an Associate, and a mature student, he is aware of how valuable upReach’s work is. He has a passion for social mobility and mental health and is excited to make a positive change in the world. Outside of work, Dan can be found travelling, gaming or hanging out with his two pugs, Alfie and Beau.",
          },
          {
            image: `${David}`,
            name: "David Steel",
            title: "Employer Partnerships Manager",
            description:
              "Throughout his time at university David helped sixth form students from less-privileged backgrounds overcome the many barriers to university. He came to realise that it was these students that needed extra support during the transition which follows university, into employment. This is why he joined upReach. David has a strong interest in history and enjoys playing any sport that is thrown his way.",
          },
          {
            image: `${Dulcie}`,
            name: "Dulcie Huwew",
            title: "Programme Leader",
            description:
              "Dulcie joined upReach as she wanted to work in an organisation that made a signifiant difference to students’ lives, and aims to make a real change in the Higher Education sector. After applying to University through a scheme called Access to Leeds, and understanding the challenges of adapting to University and the transitions that follow, Dulcie is passionate about ensuring that access to graduate employment is inclusive and accessible, and wants to ensure that all students are given equal opportunities. Her main interests are cooking, travelling, and learning about different cultures.",
          },
          {
            image: `${Ella}`,
            name: "Ella Shaxson",
            title: "Programme Coordinator",
            description:
              "Ella joined upReach as a Programme Coordinator after studying Social Anthropology at the University of Cambridge. While at university, she volunteered as an access mentor, supporting offer-holders from less advantaged backgrounds during their process of revising for A levels, meeting their offer and preparing for university life. She is excited to continue the vital mission of reducing socioeconomic inequalities while working with upReach. In her spare time, Ella loves cooking, hiking and trips to the beach.",
          },
          {
            image: `${freya}`,
            name: "Freya Mumford",
            title: "Programme Coordinator",
            description:
              "Freya joined the FCLP after graduating from the University of Liverpool with a BA in Criminology. Originally from Merseyside and working as a Youth Worker before starting university, she is passionate about social change and supporting young people in realising their potential. In her spare time Freya enjoys live music and playing guitar.",
          },
          {
            image: `${Gravin}`,
            name: "Gavin Davis",
            title: "Finance & Operations Manager",
            description:
              "Gavin is responsible for all things finance-related at upReach. Before making a career shift into the Third Sector and re-training as a Management Accountant, he co-founded and ran a niche recruitment business for over 10 years. Prior to joining upReach, Gavin worked as Finance and Administration Manager for a leading Arts Charity in East London. When not staring at various spreadsheets, he enjoys theatre and quality box-sets.",
          },
          {
            image: `${Halima}`,
            name: "Halima Aidarus",
            title: "Business Analyst",
            description:
              "Halima recently graduated from SOAS University with a degree in Arabic and English. She joined the Civil Service Fast Stream after her studies and is currently completing a secondment at upReach. As a former upReach Associate, Halima has experienced firsthand how valuable the work upReach does. Working as a tutor during university fuelled her passion for improving access to education for all, regardless of their background, so that everyone has a fair chance in employability. Outside of work, Halima enjoys baking, travelling and (more often than not) watching a good show on Netflix!",
          },
          {
            image: `${Hannah}`,
            name: "Hannah Judge",
            title: "Programme Coordinator",
            description:
              "Hannah joined upReach after completing an MSc in Educational Research at the University of Bristol. With previous experience working for universities and charities to widen participation in university study, she is excited to move into the field of improving access to competitive employment. Away from the office, Hannah enjoys climbing, travel and working her way around all of London’s coffee shops!",
          },
          {
            image: `${stoddart}`,
            name: "Hannah Stoddart",
            title: "Sector Programmes & Mentoring Manager",
            description:
              "Hannah is the Sector Programmes and Mentoring Manager. She joined upReach because she wants to help students reach their full potential, and ensure that no one feels their background can stop them from achieving their career goals. Her favourite things are tea, cake, and history.",
          },
          // {
          //   image: `${Iria}`,
          //   name: "Iria Gomez Garcia",
          //   title: "Recruitment Lead",
          //   description:
          //     "Iria joined upReach with the drive and passion to help students from different backgrounds prepare and develop the skills to access top graduate jobs. Prior to working here Iria graduated in Politics and Latin American Studies from the College of William and Mary in the US. In her spare time, Iria enjoys reading and cooking, and especially loves knitting in the winter.",
          // },
          {
            image: `${Jacque}`,
            name: "Jacque Dieu",
            title: "Technology and Data Lead",
            description:
              "Jacque is a programme coordinator, is passionate about social mobility and has previously worked on increasing access to higher education in Devon. In his free time Jacque enjoys playing the guitar, and ukulele and likes to play instrumental music and the occasional jazz standard. Jacque likes hikes in the countryside and listening to live music.",
          },
          {
            image: `${Jamie}`,
            name: "Jamie Pitts",
            title: "Senior Project Manager",
            description:
              "Jamie is our Senior Project Manager. He joins upReach after spending three years at Cancer Research UK, most recently as an Executive Project Manager. Whilst at university, he interned with a number of charities, centred around working with young people. He enjoys American Football and anything food-related.",
          },
          {
            image: `${Laura}`,
            name: "Laura Bland",
            title: "Programme Coordinator",
            description:
              "After graduating with a degree in Psychology from the University of Bath, Laura joined upReach as a Programme Coordinator. She believes passionately that everyone should have access to the careers they want. She is excited to be able to help students from less-advantaged backgrounds overcome graduate employment barriers and achieve their full potential. Outside of work, Laura enjoys spending time with her two dogs, watching stand-up comedy and live music.",
          },
          {
            image: `${Liberty}`,
            name: "Liberty O'Hagan",
            title: "University Partnership Lead",
            description:
              "Liberty first heard about upReach through her shortlisted nomination at the 2018 Student Social Mobility Awards for her voluntary work at university. She is very excited to join upReach to be part of a team that is enthusiastic to help improve social mobility and support a wide inspiring cohort of students. In her spare time Liberty likes reading about world politics, being near the sea and travelling! ",
          },
          {
            image: `${Lee}`,
            name: "Lee Robertson",
            title: "Springboard Manager",
            description:
              "Lee is responsible for running three of upReach’s “Career Springboard” programmes. He oversees the support we offer to students pursuing a career in Law, Technology and Investment Management. Lee joined upReach because he is passionate about helping students access jobs they previously wouldn’t have imagined, and giving them the extra boost they need to reach their potential.",
          },
          {
            image: `${lidia}`,
            name: "Lidia Barbuti",
            title: "Senior Brand, Marketing & Communications Officer",
            description:
              "Lidia is our Senior Brand, Marketing and Communications Officer and is responsible for maintaining brand consistency and supporting upReach’s external communications. As the first in her family to attend university, she was drawn to upReach’s mission to help less-advantaged students succeed in the graduate market. She brings with her an agency background in content production, creative development and brand management. When she isn’t working, you can find Lidia learning to argentine tango or snuggling up with her two dogs.",
          },
          {
            image: `${Liam}`,
            name: "Liam Flannigan",
            title: "Rise Programmes & Impact Manager",
            description:
              "Liam is coordinating the upReach University Societies. He joined upReach because he is passionate about bridging the attainment gap in graduate employment for students from different backgrounds. In his spare time, Liam enjoys football, travelling and cycling everywhere he can.",
          },
          {
            image: `${Mabel}`,
            name: "Mabel Canham",
            title: "Technology Springboard Lead",
            description:
              "Mabel joined upReach as she is passionate about dismantling systemic oppression and inequality. In her spare time, Mabel enjoys true crime documentaries and is a regular attendee at her weekly pub quiz. She can often be found sporting out-there nail designs and adores all things animal print. ",
          },
          {
            image: `${Muhammad}`,
            name: "Muhammad Gangat",
            title: "Programme Coordinator",
            description:
              "Muhammad is a Law Programme Coordinator. He joined upReach having just completed his LPC at the University of Law. He is extremely passionate about Social Mobility and has a keen interest in ensuring that candidates from socially mobile backgrounds can get the job of their dreams! Outside of work, Muhammad is a huge Arsenal fan (which often compounds him to immense misery!).",
          },
          {
            image: `${Niamh}`,
            name: "Niamh Mac Namee",
            title: "Programme Leader",
            description:
              "Having been an upReach associate at the University of Exeter, Niamh was very keen to join upReach to be part of their ambitious vision to facilitate equal access to opportunities and to work with a diverse and interesting group of Associates. Before joining upReach she studied Middle East Studies and taught English to children and university students abroad.",
          },
          {
            image: `${Olivia}`,
            name: "Olivia Reeve",
            title: "Fundraising & External Affairs Manager",
            description:
              "Olivia is our Fundraising Manager, and coordinates the Rise London programme in partnership with John Lyon’s Charity. After working to help prepare sixth formers for the transition to university, she was attracted to upReach’s focus on the transition which follows, between university and employment. Olivia enjoys travelling and has researched primates in various rainforests in the tropics.",
          },
          {
            image: `${Becca}`,
            name: "Rebecca O'Leary",
            title: "Programme Coordinator",
            description:
              "Becca is a Programme Coordinator at upReach. Having graduated in 2018 with a BSc Psychology degree from the University of Bath, she has since worked within Student Recruitment. She is very passionate about addressing social mobility and helping young people achieve their full potential. Outside of work, Becca enjoys baking, travelling and a good pub quiz!",
          },
          {
            image: `${Rebecca}`,
            name: "Rebecca Ige",
            title: "Programme Officer",
            description:
              "Rebecca recently graduated from the University of Cambridge with a degree in Geography. She is passionate about tackling inequalities in the education system and has worked at a number of university outreach events as a Student Ambassador, contributing to Cambridge’s commitment to widening participation. Across her education, she received a lot of support from social mobility charities and is now excited to contribute to upReach’s mission of helping students from disadvantaged backgrounds reach their full career potential. Outside of work, Rebecca enjoys reading, baking and travelling! ",
          },
          {
            image: `${sam_fra}`,
            name: "Sam Fraine",
            title: "Senior Fundraising & Events Officer",
            description:
              "Sam joined upReach in December 2020. He brings with him a wealth of experience in fundraising, project and event management in London, Lyon and Berlin, and the determination to help drive positive social change for less-advantaged undergraduates across the UK. Talk to him about good music, good food and good literature and he'll be happy.",
          },
          {
            image: `${Sam}`,
            name: "Sam Gill",
            title: "Senior HR Officer",
            description:
              "Sam recently completed an MSc in Human Resource Management at the University of Salford and has worked in diversity and inclusion in Higher Education before joining upReach. Sam has also worked with local charities and social enterprises, and is passionate about helping people to reach their full potential.",
          },
          {
            image: `${Sarah1}`,
            name: "Sarah Gregory",
            title: "Programme Leader",
            description:
              "Sarah is a Programme Leader at upReach. Originally from Liverpool, she graduated from Royal Holloway with BA Modern History and Politics. She then went on to study Economic History MSc at the London School of Economics and is passionate about the reduction of inequality in society. In her free time she enjoys cooking and spending time with her dog, Charlie.",
          },
          {
            image: `${sophie}`,
            name: "Sophie Savage",
            title: "Programme Coordinator",
            description:
              "Sophie joined the FCLP after studying PPE at the University of Leeds. She joined upReach because she is passionate about combating social and economic inequality. Outside of work, Sophie can be found painting or in the park reading, weather permitting.",
          },
          {
            image: `${Tabby}`,
            name: "Tabitha Carrington",
            title: "Programme Leader",
            description:
              "Tabitha is our Programme Leader for Exploring and Coaching, and organiser of the 2019 Student Social Mobility Awards. After working as a Sutton Scholars Mentor and President of Sexpression Warwick while at university, she was keen to further develop her knowledge of the charity sector. upReach’s ambitious Theory of Change and ever-growing impact on social mobility issues further drew her to the role. Tabitha enjoys collecting houseplants, listening to all sorts of podcasts and eating vegan treats!",
          },
          {
            image: `${Yolanda}`,
            name: "Yolanda Kisenyi",
            title: "Programme Leader",
            description:
              "Yolanda joined upReach because of her passion for contributing to social change and mentoring young people from under-represented communities. Before completing her Masters in African Studies at the University of Oxford, Yolanda was a member of the Executive Team for the Patchwork Foundation Masterclass Programme, further propelling her to pursue a career whereby she can have a positive social impact. Yolanda enjoys museums and anything to do with colonial history.",
          },
        ],
      },
    ],
  },
];
const Team = ({ data }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const mainUrl = process.env.GATSBY_API_URL;
  const changeTabs = i => {
    setActiveTabIndex(i);
  };

  return (
    <StyledWrapper>
      <div className="container-fluid mt-5 p-0">
        <h1 className="team-row">TEAM</h1>
        <div className="row pl-1 team-nav-row">
          {React.Children.toArray(
            TeamInfo[0].teamCategory.map((category, i) => (
              <div className="team-nav">
                <p
                  className="team-categories mt-2 mt-md-4"
                  onClick={() => changeTabs(i)}
                >
                  <span
                    className={
                      activeTabIndex === i
                        ? "border-on-hover active"
                        : "border-on-hover"
                    }
                  >
                    {category.title}
                  </span>
                </p>
              </div>
            ))
          )}
        </div>
        <div className="row">
          {activeTabIndex === 0 &&
            data
              .filter(category => category.id === 62)
              .map(item =>
                item.Category.map(
                  item =>
                    item.Image && (
                      <div className="col-12 col-md-6 col-lg-4 mt-5">
                        <div className="container padding-sides">
                          <div>
                            <img
                              className="img-fluid upreach-employee-img mx-auto d-block"
                              src={mainUrl + item.Image.url}
                              alt=""
                            />
                            <h5 className="mb-0 team-names mt-3 text-center">
                              {item.Name}
                            </h5>
                            <h5 className="mb-0 team-names mt-1 text-center">
                              {item.Designation}
                            </h5>
                          </div>
                          <div className="overlay">
                            <div className="text px-2">
                              <p className="mb-0 team-names">{item.Name}</p>
                              <p className="team-title">{item.Designation}</p>
                              <p className="mb-0 team-description">
                                {item.Description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )
              )}
          {activeTabIndex === 1 &&
            data
              .filter(category => category.id === 63)
              .map(item =>
                item.Category.map(
                  item =>
                    item.Image && (
                      <div className="col-12 col-md-6 col-lg-4 mt-5">
                        <div className="container padding-sides">
                          <div>
                            <img
                              className="img-fluid upreach-employee-img mx-auto d-block"
                              src={mainUrl + item.Image.url}
                              alt=""
                            />
                            <h5 className="mb-0 team-names mt-3 text-center">
                              {item.Name}
                            </h5>
                            <h5 className="mb-0 team-names mt-1 text-center">
                              {item.Designation}
                            </h5>
                          </div>
                          <div className="overlay">
                            <div className="text px-2">
                              <p className="mb-0 team-names">{item.Name}</p>
                              <p className="team-title">{item.Designation}</p>
                              <p className="mb-0 team-description">
                                {item.Description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )
              )}
          {activeTabIndex === 2 &&
            data
              .filter(category => category.id === 64)
              .map(item =>
                item.Category.map(
                  item =>
                    item.Image && (
                      <div className="col-12 col-md-6 col-lg-4 mt-5">
                        <div className="container padding-sides">
                          <div>
                            <img
                              className="img-fluid upreach-employee-img mx-auto d-block"
                              src={mainUrl + item.Image.url}
                              alt=""
                            />
                            <h5 className="mb-0 team-names mt-3 text-center">
                              {item.Name}
                            </h5>
                            <h5 className="mb-0 team-names mt-1 text-center">
                              {item.Designation}
                            </h5>
                          </div>
                          <div className="overlay">
                            <div className="text px-2">
                              <p className="mb-0 team-names">{item.Name}</p>
                              <p className="team-title">{item.Designation}</p>
                              <p className="mb-0 team-description">
                                {item.Description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )
              )}
          {activeTabIndex === 3 &&
            data
              .filter(category => category.id === 66)
              .map(item =>
                item.Category.map(
                  item =>
                    item.Image && (
                      <div className="col-12 col-md-6 col-lg-4 mt-5">
                        <div className="container padding-sides">
                          <div>
                            <img
                              className="img-fluid upreach-employee-img mx-auto d-block"
                              src={mainUrl + item.Image.url}
                              alt=""
                            />
                            <h5 className="mb-0 team-names mt-3 text-center">
                              {item.Name}
                            </h5>
                            <h5 className="mb-0 team-names mt-1 text-center">
                              {item.Designation}
                            </h5>
                          </div>
                          <div className="overlay">
                            <div className="text px-2">
                              <p className="mb-0 team-names">{item.Name}</p>
                              <p className="team-title">{item.Designation}</p>
                              <p className="mb-0 team-description">
                                {item.Description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )
              )}
          {activeTabIndex === 4 &&
            data
              .filter(category => category.id === 65)
              .map(item =>
                item.Category.map(
                  item =>
                    item.Image && (
                      <div className="col-12 col-md-6 col-lg-4 mt-5">
                        <div className="container padding-sides">
                          <div>
                            <img
                              className="img-fluid upreach-employee-img mx-auto d-block"
                              src={mainUrl + item.Image.url}
                              alt=""
                            />
                            <h5 className="mb-0 team-names mt-3 text-center">
                              {item.Name}
                            </h5>
                            <h5 className="mb-0 team-names mt-1 text-center">
                              {item.Designation}
                            </h5>
                          </div>
                          <div className="overlay">
                            <div className="text px-2">
                              <p className="mb-0 team-names">{item.Name}</p>
                              <p className="team-title">{item.Designation}</p>
                              <p className="mb-0 team-description">
                                {item.Description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )
              )}
          {activeTabIndex === 5 &&
            data
              .filter(category => category.id === 67)
              .map(item =>
                item.Category.map(
                  item =>
                    item.Image && (
                      <div className="col-12 col-md-6 col-lg-4 mt-5">
                        <div className="container padding-sides">
                          <div>
                            <img
                              className="img-fluid upreach-employee-img mx-auto d-block"
                              src={mainUrl + item.Image.url}
                              alt=""
                            />
                            <h5 className="mb-0 team-names mt-3 text-center">
                              {item.Name}
                            </h5>
                            <h5 className="mb-0 team-names mt-1 text-center">
                              {item.Designation}
                            </h5>
                          </div>
                          <div className="overlay">
                            <div className="text px-2">
                              <p className="mb-0 team-names">{item.Name}</p>
                              <p className="team-title">{item.Designation}</p>
                              <p className="mb-0 team-description">
                                {item.Description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )
              )}
        </div>
      </div>
    </StyledWrapper>
  );
};
export default Team;
