import React from "react";
import { Link, graphql } from "gatsby";
import axios from "axios";
import { useEffect, useState } from "react";
import HeroImages from "../components/Reusables/HeroImages";
import Layout from "../components/layout";
import SummaryBlock from "../components/Home/SummaryBlock";
import styled from "styled-components";
import HoverEffectBlocks from "../components/About/HoverEffectBlock";
import GetInvolved from "../components/Home/GetInvolved";
import AwardAccordion from "../components/About/AwardAcordion";
import Team from "../components/About/Team";
import SEO from "../components/seo";
import hero from "../images/pageImages/About/Hero.jpg";
import theoryChange from "../images/pageImages/About/TheoryChange.jpg";
import WhyImportant from "../images/pageImages/About/WhyImportant.jpg";
import impact1 from "../images/pageImages/About/Impact-1.jpg";
import impact2 from "../images/pageImages/About/Impact-2.jpg";
import impact3 from "../images/pageImages/About/Impact-3.jpg";
import story from "../images/pageImages/About/Story.jpg";
import StatisticBox from "../components/Home/StatisticBlock";
import award2 from "../images/pageImages/About/Award2.jpg";
import award3 from "../images/pageImages/About/Award3.jpg";
import winner from "../images/pageImages/About/Award1.jpg";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div`
  .flat-button {
    display: none;
  }

  .get-involved {
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 36px;

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .link-text {
    font-size: 22px;
    line-height: normal;

    @media (max-width: 600px) {
      font-size: 15px;
    }
  }
`;

const summary = [
  {
    title: "Our Story",
    description:
      "upReach helps disadvantaged students from across the UK realise their potential by investing in pioneering technology to facilitate, deliver and evaluate highly personalised programmes of support. Through successful partnerships with top employers and universities, we offer students access to a comprehensive range of opportunities and activities to broaden their horizons, understand career pathways and develop the skills, networks and experiences needed for professional success. We also collaborate closely with other charities in our sector to expand the support offered and maximise our joint impact.",
    image: `${story}`,
  },
];

const theoryBody = (
  <div>
    <p>
      upReach’s vision is of a society in which everybody has an equal
      opportunity to realise their full career potential, regardless of social
      background.
    </p>
    <p>
      Socioeconomic disadvantage continues to be the most significant driver of
      inequality in terms of access to and outcomes from higher education.
    </p>
    <p>
      We take a ‘double benefit’ approach to address the social mobility
      challenge.
    </p>
    <p>
      We help young people achieve their career potential by providing an
      intensive programme of support that addresses socioeconomic barriers to
      employment.
    </p>
    <p>
      We also work to influence the hiring behaviours of top organisations so
      that a commitment to equal opportunity permeates their organisational
      culture and they recognise the social and economic value of greater
      diversity within their workforce.
    </p>
  </div>
);

const Theory = [
  {
    title: "Theory of Change",
    description: theoryBody,
    image: `${theoryChange}`,
  },
];

const ExistBody = (
  <div>
    <p>
      Social mobility is a big problem in the UK and the top professions
      continue to be dominated by those who went to private schools. More
      students than ever from socioeconomically disadvantaged backgrounds attend
      university but this has not translated to increased income or enrolment in
      competitive graduate programmes at leading firms. This is the most
      significant driver of inequality in society.
    </p>
    <ul>
      <li>
        Non-selective state schools educate 89% of the population, yet account
        for only 30% of those on some graduate schemes - Dr. Louise Ashley
      </li>
      <li>
        Graduates from lower income households earn 10% less than others
        studying the same course at the same university - IFS
      </li>
      <li>
        74% of judges, 71% of barristers, 51% of journalists went to private
        schools, which educates only 7% of the population - Sutton trust
      </li>
    </ul>
  </div>
);

const Exist = [
  {
    title: "Why is this important?",
    description: ExistBody,
    image: `${WhyImportant}`,
  },
];

const data = [
  {
    title:
      "Founded in 2012, our initial cohort of 39 students has grown to 1,800 strong today and we hope to accommodate 2,500 by 2022. From dedicated skills workshops to live video forums, the intensive career-support programme has helped students develop the social and cultural capital necessary to secure jobs at 74 of top 100 employers.",
  },
  {
    links: [
      {
        link: "Download upReach Impact Report 2020",
        href:
          "https://www.paperturn-view.com/uk/upreach/upreach-impact-report-2018-2019?pid=MTM131595&v=1.1",
        externalLink: true,
      },
      // { link: "Download upReach Impact Report 2019", href: "https://upreach.org.uk/wp-content/uploads/2019/07/upReach-Impact-Report-2016-2018.pdf" },
      // { link: "Download upReach Annual Report 2019", href: "https://upreach.org.uk/wp-content/uploads/2016/11/200529_Annual-Report_IG_Non_Printable-1.pdf" },
    ],
  },
];

const statistic = [
  {
    numbers: 78,
    description:
      "of students secured professional employment or pursued further study 6 months after graduation.",
    color: "#464FD0",
    suffix: "%",
  },
  {
    numbers: 3.2,
    description:
      "first-year students part of our programme have 3.2 times the average employability skill progression.",
    color: "#304563",
    suffix: "x",
  },
  {
    numbers: 28000,
    description:
      "first-degree graduates in full-time employment had a median salary of £26,000; £4,000 higher than the average.",
    color: "#FF8600",
    prefix: "£",
  },
];

const awardAccordionFirstBody = (
  <div>
    "In May 2020, upReach was announced as the winner of the “Embracing Digital”
    Charity Governance Award. The full shortlist included 21 UK charities
    engaged in a diverse range of activities such as health care, support for
    victims of domestic abuse, and education access for those experiencing
    disadvantage. upReach was selected because “Our judges were impressed with
    the way digital has been embraced by the board, and utilised to improve
    internal practices as well…The use of digital to capture, monitor and
    evaluate impact was of particular note.” Find out more{" "}
    <Link
      target="_blank"
      className="here-link"
      to="https://www.charitygovernanceawards.co.uk/short-list/upreach/?landing=winner"
    >
      {" "}
      here
    </Link>
    ",
  </div>
);

const accordion = [
  {
    image: `${winner}`,
    shortDescription: "2020 Charity Governance Awards – Embracing Digital",
    description: awardAccordionFirstBody,
  },
  {
    image: `${award2}`,
    shortDescription: "2019 Charity of the Year",
    description:
      "In October 2019, upReach won the Charity of the Year Award at the Charity Times Awards, in the under £1m income category. The Award was in recognition of boosting social mobility, launching the Student Social Mobility Awards, our successful Future Charity Leaders Programme and sector-leading technology.",
  },
  {
    image: `${award3}`,
    shortDescription: "FSI Small Charity Big Impact Award – Highly Commended",
    description:
      "In July 2019, in recognition of the growing impact that upReach is having, the charity was Highly Commended at the FSI Small Charity Big Impact Awards in our income category.",
  },
];

const heroImage = { hero: `${hero}` };

const StatsHeading = { title: "OUR IMPACT" };

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <About />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/about-page`);
};
const fetchHeroImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/hero-images`);
};
const fetchStats = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/stats`);
};
const fetchTeams = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/teams`);
};

const About = () => {
  const [newData, setNewData] = useState(null);
  const [heroImages, setHeroImages] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const [teamsData, setTeamsData] = useState(null);
  const { data: pageData } = useQuery("about", fetchData);
  const { data: heroImage } = useQuery("aboutImages", fetchHeroImage);
  const { data: stats } = useQuery("aboutStats", fetchStats);
  const { data: teams } = useQuery("aboutTeams", fetchTeams);

  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (heroImage !== undefined) {
      setHeroImages(heroImage.data);
    }
    if (stats !== undefined) {
      setStatsData(stats.data);
    }
    if (teams !== undefined) {
      setTeamsData(teams.data);
    }
  }, [pageData, heroImage, stats, teams]);

  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription} />}
        {newData &&
          newData.showHeroImage &&
          heroImages &&
          heroImages.length > 0 &&
          heroImages.map(
            item =>
              item.Slug.toLowerCase() === "AboutPage".toLowerCase() &&
              item.Image && <HeroImages heroImage={item.Image.url} />
          )}

        {newData && newData.showSummary && newData.Summary && (
          <SummaryBlock summary={newData.Summary} />
        )}

        {newData &&
          newData.showStatistics &&
          statsData &&
          statsData.length > 0 &&
          statsData.map(
            item =>
              item.slug.toLowerCase() === "About Page".toLowerCase() &&
              item.StudentStatistics.length > 0 && (
                <StatisticBox statistic={item.StudentStatistics} />
              )
          )}

        {newData && newData.showTheory && newData.Theory && (
          <SummaryBlock summary={newData.Theory} />
        )}
        {newData && newData.showInvolved && newData.involved && (
          <GetInvolved data={newData.involved} />
        )}
        {newData &&
          newData.showWhyImportantBlock &&
          newData.whyImportantBlock && (
            <SummaryBlock summary={newData.whyImportantBlock} />
          )}
        {newData && newData.showAwardAccordion && (
          <div
            className="container-fluid padding-left-8 padding-right-8 margin-top-100"
            id="awards"
          >
            <h1 className="mb-3">{newData.AwardTitle}</h1>
            <AwardAccordion accordion={newData.AwardAccordian} />
          </div>
        )}
        {newData && newData.showTeam && teamsData && teamsData.length > 0 && (
          <div className="container-fluid padding-left-8 padding-right-8 margin-top-100">
            <Team data={teamsData} />
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
};
